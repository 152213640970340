import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/clean.css";
import ElementUI from "element-ui";
import "./assets/icon/iconfont.css";
import "element-ui/lib/theme-chalk/index.css";
import "./utils/rem";
import "lib-flexible";
import axios from "axios";
import qs from "qs";
import Alert from "./component/alert";
import preventReClick from "./utils/preventReClick"; //防多次点击，重复提交
import * as filters from "./utils/filters";
import "./permission";
import "vant/lib/index.css";
import "./utils/request.js";
import {
  Icon,
  Popup,
  Button,
  RadioGroup,
  Radio,
  Dialog,
  Toast,
  Cell,
  CellGroup,
  Divider,
  Checkbox,
  CheckboxGroup,
  Empty,
  Tag,
  Field,
  ContactEdit,
} from "vant";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
axios.defaults.baseURL = "/api"; //关键代码

Vue.use(ElementUI);
Vue.use(preventReClick);
Vue.use(Alert);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Divider);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Field);
Vue.use(ContactEdit);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-launch-weapp"];

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
