import router from "./router";

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach(() => {
  // 微信环境下禁用分享
  const ua = navigator.userAgent.toLowerCase();
  // eslint-disable-next-line eqeqeq
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.call("hideOptionMenu");
  }
});
